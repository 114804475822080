h1 {
  font-size: $h1-font-size;
  @include max767 {
    font-size: $h1-font-size-resp;
  }
}

h2 {
  font-size: $h2-font-size;
  @include max767 {
    font-size: $h2-font-size-resp;
  }
}

h3 {
  font-size: $h3-font-size;
  @include max767 {
    font-size: $h3-font-size-resp;
  }
}

h4 {
  font-size: $h4-font-size;
  @include max767 {
    font-size: $h4-font-size-resp;
  }
}

h5 {
  font-size: $h5-font-size;
  @include max767 {
    font-size: $h5-font-size-resp;
  }
}

h6 {
  font-size: $h6-font-size;
  @include max767 {
    font-size: $h6-font-size-resp;
  }
}
